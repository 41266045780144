@tailwind base;
@tailwind components;
@tailwind utilities;


#policy {
    -webkit-clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 10%, 100% 90%, 0 100%);
    background-color: #e6fafa;}

#img-area {
    background:linear-gradient(90deg,white 0%,white 30%,#e6fafa 0%,#e6fafa 100%);
}

#about-wrapper {
    background:linear-gradient(90deg,white 0%,white 60%,#e6fafa 0%,#e6fafa 100%);

}

#ContactBackgroundImage {
background-image:url("https://cachitto-page.com/image/sample-image5.jpg");
background-size:cover;
background-color:rgba(0,0,0,0.3);
background-blend-mode:darken;
}

.Square li {
  list-style-type: disc;
}

.Title span{
    line-height:20%;
}












body {
  font-family: Alegreya Sans;
}
.menu {
  position: ralative;
  z-index:100;
  background: White;
  width: 3em;
  height: 5em;
  border-radius: 5em 0em 0em 5em ;
  margin: auto;
  margin-top: 15em;
  margin-right:0;
  margin-bottom: 5em;
  cursor: pointer;
  border: 2em solid #000099;
}
.menu:after {
  content: "";
  position: absolute;
  top: 1em;
  left: 1em;
  width: 1em;
  height: 0.2em;
}
.menu ul {
  list-style: none;
  padding: 0;
}
.menu li {
  width:5rem;
  height: 5em;
  border-radius:50px;
  padding: -1.2em;
  margin-top: -1.2em;
  text-align: center;
  transition: all 1s;
  background: #000099;
  opacity: 0;
  z-index: -1;
  border:#fff solid 1px;

}
.menu:hover li {
  opacity: 1;
}
/**
 * Add a pseudo element to cover the space
 * between the links. This is so the menu
 * does not lose :hover focus and disappear
 */
.menu:hover ul::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  display: block;
  left: 50%;
  top: -5.0em;
  /**
   * The pseudo-element is a semi-circle
   * created with CSS. Top, bottom, and right
   * borders are 6.5em (left being 0), and then
   * a border-radius is added to the two corners
   * on the right.
   */
  border-width: 6.5em;
  border-radius: 0 7.5em 7.5em 0;
  border-left: 0;
  border-style: solid;
  /**
   * Have to have a border color for the border
   * to be hoverable. I'm using a very light one
   * so that it looks invisible.
   */
  border-color: rgba(0,0,0,0.01);
  /**
   * Put the psuedo-element behind the links
   * (So they can be clicked on)
   */
  z-index: -1;
  /**
   * Make the cursor default so it looks like
   * nothing is there
   */
  cursor: default;
}
.menu a {
  color: white;
  text-decoration: none;
  /**
   * This is to vertically center the text on the
   * little tab-like things that the text is on.
   */
  line-height: 1.5em;
}
.menu a {
  color: white;
  text-decoration: none;
}
.menu ul {
/* hoverした時に回る角度 */
  transform: rotate(360deg) translateY(-2em);
  transition: 1s all;
}
.menu:hover ul {
  transform: rotate(180deg) translateY(-1.75em);
}
.menu li:hover {
  border:White solid 1px;
  z-index: 10;
  
}


 /*バー関連*/
.menu li:nth-of-type(1) {
  transform: rotate();
  position: absolute;
  left: 0em;
  top: -8.2em;
}
.menu li:nth-of-type(2) {
  transform: rotate();
  /*バーの角度*/
  position: absolute;
  left: 4em;
  top: -4.4em;
}
.menu li:nth-of-type(3) {
  position: absolute;
  left: 6em;
  top: 0.6em;
}
.menu li:nth-of-type(4) {
  transform: rotate();
  position: absolute;
  left: 4em;
  top: 6.4em;
}
.menu li:nth-of-type(5) {
  transform: rotate();
  position: absolute;
  left: 0em;
  top: 10em;
}
.hint {
  text-align: center;
}
